@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback');

@import 'tailwindcss/base';
@import 'tailwindcss/components';

/* Additional styles */
@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/range-slider.css';
@import 'additional-styles/toggle-switch.css';
@import 'additional-styles/theme.css';

@import 'tailwindcss/utilities';

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
@layer utilities {
    .rtl {
        direction: rtl;
    }
}

/* See Alpine.js: https://github.com/alpinejs/alpine#x-cloak */
[x-cloak=""] {
    display: none;
}

:root {
    --color-primary: #3490dc;
    --color-secondary: #38b2ac;
    --color-accent: #d0a4ff;
    --color-background: #f7fafc;
    --color-text: #2d3748;
    --color-text-light: #718096;
  }
  
  body {
    background-color: var(--color-background);
    color: var(--color-text);
    font-family: 'Inter', sans-serif;
  }
  
  .gradient-text {
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-image: linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  }
  
  .btn-primary {
    background-color: var(--color-primary);
    color: white;
    padding: 0.5rem 0.5rem;
    border-radius: 0.375rem;
    font-weight: 500;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    outline: 2px solid transparent;
  }
  
  .btn-primary:hover {
    background-color: var(--color-secondary);
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .btn-primary:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .btn-secondary {
    background-color: var(--color-accent);
    color: white;
    padding: 0.5rem 0.5rem;
    border-radius: 0.375rem;
    font-weight: 500;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    outline: 2px solid transparent;
  }
  
  .btn-secondary:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    filter: brightness(105%);
  }
  
  .btn-secondary:active {
    transform: translateY(0);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  /* Logo animation */
  @keyframes mountainGrow {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  .mountain-animation {
    animation: mountainGrow 1s ease-out forwards;
    transform-origin: center;
  }
  
  @keyframes logo-gradientShift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .logo-animate-gradient {
    background-size: 200% 200%;
    animation: logo-gradientShift 8s ease infinite;
  }
  
  .logo-text-shadow {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  
  .logo-letter-spacing-wide {
    letter-spacing: 0.05em;
  }